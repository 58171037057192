import * as React from 'react';
import SEO from '../../components/global/seo';
import OtherLayout from '../../components/other/other-layout';
import SolutionMdx from '../../components/solution/solution.mdx';

import './index.scss';

const SolutionPage = () => (
  <OtherLayout className="solution-page">
    <SEO title="Solutions" />

    <SolutionMdx/>

  </OtherLayout>
);

export default SolutionPage;
