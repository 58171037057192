import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Solutions`}</h1>
    <p>{`Nous n’avons pas de solutions miraculeusement adaptées à tous les projets.`}</p>
    <p>{`N’hésitez pas à nous contacter pour nous parler de votre projet, et nous pourrons voir ensemble quel accompagnement
vous proposer, aussi bien pour la phase de réflexion initiale que pendant sa réalisation. `}</p>
    <p>{`Voici un aperçu de nos domaines d’activité`}{` `}{`:`}</p>
    <article className="solution-item">
      <h3>{`Support, ante-création, audits et formations`}</h3>
      <ul>
        <li parentName="ul">{`Aide à la faisabilité, étude comparative des différentes solutions.`}</li>
        <li parentName="ul">{`Définition et stratégie du MVP.`}</li>
        <li parentName="ul">{`Audit des solutions informatiques.`}</li>
        <li parentName="ul">{`Formations `}<em parentName="li">{`(voir page dédiée)`}</em>{`. `}</li>
      </ul>
    </article>
    <article className="solution-item">
      <h3>{`Architecture logicielle`}</h3>
      <ul>
        <li parentName="ul">{`Conception d’une architecture correspondant aux besoins et au contexte.`}</li>
        <li parentName="ul">{`Évaluation et choix de technologies pertinentes pour le projet.`}</li>
        <li parentName="ul">{`Modélisation objet de processus métier.`}</li>
        <li parentName="ul">{`Applications web, frontend, backend, API REST, frameworks.`}</li>
      </ul>
    </article>
    <article className="solution-item">
      <h3>{`Bonnes pratiques de développement logiciel`}</h3>
      <ul>
        <li parentName="ul">{`Lisibilité du code.`}</li>
        <li parentName="ul">{`Patterns de conception et de modélisation.`}</li>
        <li parentName="ul">{`Méthodes agiles côté développement `}<em parentName="li">{`(Extreme Programming)`}</em>{`.`}</li>
        <li parentName="ul">{`Montée en compétence des développeurs.`}</li>
      </ul>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      